import { getRefreshToken } from 'src/api/legacy'

export const httpUrl = '/newretail'
export const storeHttp = '/newretail'
export const storeHttpV2 = '/newretail/v2'

export const formatLabel = (arr, val, key = 'value') => {
  const result = arr.find((item) => item[key] === val)
  if (result !== undefined) {
    return result.label
  }
  return null
}

export const sorter = (order, prop) => {
  return (a, b) => {
    const or = order === 'ascending' ? 1 : -1
    const aKey = a[prop]
    const bKey = b[prop]
    return aKey > bKey ? or : -or
  }
}

export const generateTableData = (dataSource, { order, prop }, { pageNumber, pageSize }) => {
  if (order) {
    return dataSource
      .slice()
      .sort(sorter(order, prop))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  } else {
    return dataSource.slice().slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }
}

export const refreshToken = () => {
  const refreshToken = localStorage.getItem('refreshToken')
  if (refreshToken) {
    getRefreshToken({
      refreshToken,
    }).then((res) => {
      localStorage.setItem('accessToken', res.data.accessToken)
      localStorage.setItem('refreshToken', res.data.refreshToken)
    })
  }
}

/** 计算码率（K、M、G） */
export const computedDataRate = (rate) => {
  if (rate < 1024) {
    return `${rate}K`
  } else if (rate < 1024 * 1024) {
    const result = (rate / 1024).toFixed(1)
    return `${parseInt(result * 10) / 10}M`
  } else {
    return `${(rate / 1024 / 1024).toFixed(1)}G`
  }
}

// 上传限速
export const formatSpeedLimit = (val, showUnit = false) => {
  if (+val === -1) {
    return '不限速'
  }
  return showUnit ? val + 'k' : val
}
